$("#dashboard-menu").click(function(e) {
  e.preventDefault();
  $("#wrapper").toggleClass("active");
  if ($("#wrapper").hasClass("active") == false) {
    $("#sidebar").hide();
  } else {
    $("#sidebar").show();
  }
});
$("#menu-toggle").click(function(e) {
  e.preventDefault();
  $("#wrapper").toggleClass("active");
  if ($("#wrapper").hasClass("active") == false) {
    $("#sidebar").hide();
  } else {
    $("#sidebar").show();
  }
});

// $('#wrapper').removeClass('active');
// }
// if($(window).width() <= 767) {
//   $('#wrapper').removeClass("active");
//   $('#wrapper').click(function(e){
//     if(e.target.id == "sidebar-wrapper") return;
//     if($(e.target).closest('#sidebar-wrapper').length) return;
//     $('#wrapper').removeClass("active");
//     $('#sidebar').hide();
//   });
// }

if ($("#wrapper").hasClass("active") == false) {
  $("#sidebar").hide();
} else {
  $("#sidebar").show();
}
function toggleChevron(e) {
  $(e.target)
    .prev(".search-heading")
    .find("i.indicator")
    .toggleClass("fa-caret-down fa-caret-right");
}

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/\_+/g, "-") // Replace _ with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

$("#accordion").on("hidden.bs.collapse", toggleChevron);
$("#accordion").on("shown.bs.collapse", toggleChevron);

var map;

function changeOption(val, option_selected, region_option_selected) {
  $.ajax({
    url: "/ajaxOptions",
    type: "GET",
    dataType: "JSON",
    data: { id: val },
    success: function(data) {
      $("#option_field").html("");
      $("#region_option_field").html("");
      $("#total_price").html("");
      $("#show_total_price").hide();
      var currency = $("#currency").html();

      $.each(data["options"], function(key, value) {
        $("#show_option").show();
        if (option_selected == value["value"]) {
          $("#option_field").append(
            '<option value="' +
              value["value"] +
              '" selected>' +
              value["name"] +
              " - Price: " +
              value["value"] +
              " " +
              currency +
              "</option>"
          );
        } else {
          $("#option_field").append(
            '<option value="' +
              value["value"] +
              '">' +
              value["name"] +
              " - Price: " +
              value["value"] +
              " " +
              currency +
              "</option>"
          );
        }
      });

      $("#region_option_field").append(
        '<option value="">Select a travel distance</option>'
      );
      if (data["travel_price"].length > 0) {
        $("#show_region_option").show();
        $.each(data["travel_price"], function(key, value) {
          if (region_option_selected == value["price"]) {
            $("#region_option_field").append(
              '<option value="' +
                value["price"] +
                '" selected>' +
                value["region_name"] +
                " - Price: " +
                value["price"] +
                " " +
                currency +
                "</option>"
            );
          } else {
            $("#region_option_field").append(
              '<option value="' +
                value["price"] +
                '">' +
                value["region_name"] +
                " - Price: " +
                value["price"] +
                " " +
                currency +
                "</option>"
            );
          }
        });
      } else {
        $("#show_region_option").hide();
      }

      $("#total_price").html("");
      var option_price = $("#option_field option:selected").val();
      var region_price = $("#region_option_field option:selected").val();
      $("#total_price").html(option_price + " " + currency);

      if (region_price) {
        var total_price = parseInt(option_price) + parseInt(region_price);
        $("#total_price").html(total_price + " " + currency);
      }
      $("#show_total_price").show();
    }
  });
}

$(document).ready(function() {
  // INIT Google Maps
  // if ($("#map").length > 0){
  //   initMap();
  // }

  // Start date and End date for booking

  var dateFormat = "dd/mm/yyyy";
  if (
    $(
      "#select_start_date,#select_start_date_single,#started_date,#release_date-field"
    ).length
  ) {
    invoice_start_date = $(
      "#select_start_date,#select_start_date_single,#started_date,#release_date-field"
    ).datepicker({
      weekStart: 1,
      format: dateFormat,
      startDate: "today",
      changeMonth: true,
      changeYear: true,
      numberOfMonths: 1
    });
    invoice_end_date = $("#select_end_date,#ended_date").datepicker({
      weekStart: 1,
      format: dateFormat,
      startDate: "today",
      changeMonth: true,
      changeYear: true,
      numberOfMonths: 1
    });
    invoice_date = $("#date_of_birth-field").datepicker({
      weekStart: 1,
      format: dateFormat,
      changeMonth: true,
      changeYear: true,
      numberOfMonths: 1
    });
    invoice_start_date.on("change", function() {
      invoice_end_date.val($(this).val());
      invoice_end_date.datepicker("setStartDate", $(this).val());
    });
  }
  // END: Start date and End date for booking

  $("#act_id-field").change(function() {
    var val = $("#act_id-field option:selected").val();
    var option_selected = null;
    var region_option_selected = null;
    changeOption(val, option_selected, region_option_selected);
  });

  $("#option_field").change(function() {
    $("#total_price").html("");
    var option_price = $("#option_field option:selected").val();
    var currency = $("#currency").html();
    var region_price = $("#region_option_field option:selected").val();
    $("#total_price").html(option_price + " " + currency);
    if (region_price) {
      var total_price = parseInt(option_price) + parseInt(region_price);
      $("#total_price").html(total_price + " " + currency);
    }
    $("#show_total_price").show();
  });

  $("#region_option_field").change(function() {
    $("#total_price").html("");
    var currency = $("#currency").html();
    var option_price = $("#option_field option:selected").val();
    var region_price = $("#region_option_field option:selected").val();
    var total_price = parseInt(option_price) + parseInt(region_price);
    $("#total_price").html(total_price + " " + currency);
    $("#show_total_price").show();
  });

  $("a.share:not(.mail)").click(function(e) {
    e.preventDefault();
    var $link = $(this);
    var href = $link.attr("href");
    var network = $link.attr("data-network");

    var networks = {
      facebook: { width: 600, height: 300 },
      twitter: { width: 600, height: 254 },
      google: { width: 515, height: 490 },
      pinterest: { width: 600, height: 300 },
      linkedin: { width: 600, height: 300 }
    };

    var popup = function(network) {
      var dualScreenLeft =
        window.screenLeft != undefined ? window.screenLeft : screen.left;
      var dualScreenTop =
        window.screenTop != undefined ? window.screenTop : screen.top;

      var width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
      var height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

      var left = width / 2 - networks[network].width / 2 + dualScreenLeft;
      var top = height / 2 - networks[network].height / 2 + dualScreenTop;

      var options = "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,";
      window.open(
        href,
        "",
        options +
          "height=" +
          networks[network].height +
          ",width=" +
          networks[network].width +
          ", top=" +
          top +
          ", left=" +
          left
      );
    };

    popup(network);
  });

  $.ajaxSetup({
    headers: {
      "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
    }
  });

  $(".tooltipclick").click(function() {
    var id = $(this).attr("tlt");
    $("." + id).tooltip("show");
  });

  $(".submit_favorite_heart.favorite_logged").on("submit", function(e) {
    e.preventDefault();

    var parent = $(this).closest(".buttons-box");
    var heart = $(parent).find("i");
    var span = $(parent).find(".orange-btn-box-span");

    if (heart.hasClass("fa-heart-o")) {
      $(heart).removeClass("fa-heart-o");
      $(heart).addClass("fa-heart");
    } else if ($(heart).hasClass("fa-heart")) {
      $(heart).removeClass("fa-heart");
      $(heart).addClass("fa-heart-o");
    }

    $.ajax({
      type: "POST",
      url: "/add_favorite",
      data: $(this).serialize(),
      dataType: "json",
      success: function(data) {
        console.log("success");
      },
      error: function(data) {
        console.log("error");
      }
    });
  });
  $(".submit_favorite.favorite_logged").on("submit", function(e) {
    e.preventDefault();

    var div = $(this).find("#favourite-saved");
    // console.log(span);

    if (div.hasClass("fav_saved")) {
      div.removeClass("fav_saved");
      div.html("Add to favorites");
    } else {
      div.addClass("fav_saved");
      div.html("Saved to favorites");
    }

    $.ajax({
      type: "POST",
      url: "/add_favorite",
      data: $(this).serialize(),
      dataType: "json",
      success: function(data) {
        // console.log('success');
      },
      error: function(data) {
        // console.log('error');
      }
    });
  });

  var date = new Date();
  date.setDate(date.getDate());
  if ($(".datepicker").length) {
    var picker = $(".datepicker")
      .datepicker({
        format: "dd-mm-yyyy",
        todayHighlight: true,
        startDate: date,
        language: "en",
        beforeShow: function(input, inst) {
          $(this).addClass("razvan");
        }
      })
      .on("changeDate", function(e) {
        $(this).datepicker("hide");
      });
  }

  $(document).on("touchmove", function() {
    if ($(".datepicker").length) {
      // picker.datepicker('hide');
    }
  });

  $("#carousel-home").carousel({
    interval: 8000,
    pause: "hover"
  });
  // Init Chosen Select
  if ($(".chosen-select").length) {
    $(".chosen-select").chosen();
  }

  // Trigger add listing form
  $(".add-a-listing").click(function(e) {
    e.preventDefault();
    $(".submit-register-to-entertainer").submit();
  });

  // Edit booking
  $(".edit-status-for-booking").change(function() {
    var value = $(this).val();
    var booking_type = $(".edit-booking_type-for-booking").val();

    if (value == 2) {
      $(".complete-notes textarea").prop("required", true);
    } else {
      $(".complete-notes textarea").prop("required", false);
    }
    if (value == 1 && booking_type == "online") {
      $(".booking-notice").removeClass("hide");
    } else {
      $(".booking-notice").addClass("hide");
    }
  });

  $(".edit-booking_type-for-booking").change(function() {
    var value = $(this).val();
    var status = $(".edit-status-for-booking").val();

    if (value == "online" && status == 1) {
      $(".booking-notice").removeClass("hide");
    } else {
      $(".booking-notice").addClass("hide");
    }
  });

  $(".toggle-dropdown").click(function() {
    var parent = $(this).parent();
    var dropdown_container = parent.find(".dropdown-menu");
    dropdown_container.toggleClass("open-dropdown");
  });

  $(document)
    .find('select[class^="selectize-field"]:not(.selectize-location)')
    .each(function() {
      var $select = $(this).selectize({
        onDropdownOpen: function() {
          this.clear();
        },
        dataAttr: "data-extra",
        searchField: ["value", "text", "other"]
      });
    });
});

$("#myTabs a").click(function(e) {
  e.preventDefault();
  $("#mymap").removeClass("hidden");
  $("#iconlist").addClass("hidden");
  $(this).tab("show");
});

$("#mymap a").click(function(e) {
  e.preventDefault();
  $("#myTabs li").removeClass("active");
  $("#mymap").addClass("hidden");
  $("#iconlist").removeClass("hidden");
  $(this).tab("show");
  setTimeout(refreshMap, 500);
});

$("#iconlist a").click(function(e) {
  e.preventDefault();
  $('#myTabs li a[href="#highly_rated"]')
    .parent()
    .addClass("active");
  $("#mymap").removeClass("hidden");
  $("#iconlist").addClass("hidden");
  $(this).tab("show");
});

function refreshMap() {
  var center = map.getCenter();
  google.maps.event.trigger(map, "resize");
  map.setCenter(center);
}

function initMap() {
  var myLatLng = new google.maps.LatLng(-34.397, 150.644);

  map = new google.maps.Map(document.getElementById("map"), {
    zoom: 4,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    center: myLatLng
  });

  $.each(list, function(index, item) {
    var myLatLng2 = new google.maps.LatLng(-34.397 + index, 150.644 + index);

    var marker = new MarkerWithLabel({
      position: myLatLng2,
      map: map,
      draggable: true,
      raiseOnDrag: true,
      labelContent: "",
      labelAnchor: new google.maps.Point(15, 65),
      labelClass: "labels", // the CSS class for the label
      labelInBackground: true,
      icon: pinSymbol("blue")
    });

    var iw = new google.maps.InfoWindow({
      content:
        '<div class="map-box-content row">' +
        '<div class="col-md-3 text-center">' +
        '<img class="img-responsive" src="/images/booking/band.png">' +
        '<i class="fa fa-heart-o" aria-hidden="true"></i>' +
        "</div>" +
        '<div class="col-md-6">' +
        '<div class="header-box row">' +
        '<div class="col-md-12 no-padding title-box">' +
        '<div class="title">' +
        item.title +
        "</div>" +
        '<span class="pull-right">()</span>' +
        '<fieldset class="rating pull-right">' +
        '<input type="radio" id="star5" name="rating" value="5" />' +
        '<label class = "full" for="star5" title="Awesome - 5 stars"></label>' +
        '<input type="radio" id="star4half" name="rating" value="4 and a half" />' +
        '<label class="half" for="star4half" title="Pretty good - 4.5 stars"></label>' +
        '<input type="radio" id="star4" name="rating" value="4" />' +
        '<label class = "full" for="star4" title="Pretty good - 4 stars"></label>' +
        '<input type="radio" id="star3half" name="rating" value="3 and a half" />' +
        '<label class="half" for="star3half" title="Meh - 3.5 stars"></label>' +
        '<input type="radio" id="star3" name="rating" value="3" />' +
        '<label class = "full" for="star3" title="Meh - 3 stars"></label>' +
        '<input type="radio" id="star2half" name="rating" value="2 and a half" />' +
        '<label class="half" for="star2half" title="Kinda bad - 2.5 stars"></label>' +
        '<input type="radio" id="star2" name="rating" value="2" />' +
        '<label class = "full" for="star2" title="Kinda bad - 2 stars"></label>' +
        '<input type="radio" id="star1half" name="rating" value="1 and a half" />' +
        '<label class="half" for="star1half" title="Meh - 1.5 stars"></label>' +
        '<input type="radio" id="star1" name="rating" value="1" />' +
        '<label class = "full" for="star1" title="Sucks big time - 1 star"></label>' +
        '<input type="radio" id="starhalf" name="rating" value="half" />' +
        '<label class="half" for="starhalf" title="Sucks big time - 0.5 stars"></label>' +
        "</fieldset>" +
        "</div>" +
        '<div class="col-md-12 mini-description-box">' +
        "<span>" +
        item.short_description +
        "</span>" +
        "</div>" +
        "</div>" +
        '<div class="row description-box">' +
        "<span>" +
        item.description +
        "</span>" +
        "</div>" +
        "</div>" +
        '<div class="col-md-3 buttons-box">' +
        '<div class="col-md-12 text-center price-box">' +
        "<p>Price: </p>" +
        "<p>" +
        item.price +
        " $</p>" +
        "</div>" +
        '<a href="#" class="col-md-12 text-center green-btn-box"><span>Button 1</span></a>' +
        '<a href="#" class="col-md-12 text-center orange-btn-box"><span>Button 2</span></a>' +
        '<a href="/company/' +
        item.company_id +
        '" class="col-md-12 text-center blue-btn-box"><span>View Profile</span></a>' +
        "</div>" +
        "</div>"
    });

    google.maps.event.addListener(marker, "click", function(e) {
      iw.open(map, this);
    });
  });
}

function pinSymbol(color) {
  return {
    path:
      "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
    fillColor: color,
    fillOpacity: 1,
    strokeColor: "#000",
    strokeWeight: 1,
    scale: 1
  };
}

$("#book_now_modal_form").on("submit", function(e) {
  $.ajaxSetup({
    header: $('meta[name="_token"]').attr("content")
  });
  e.preventDefault(e);
  var holder = "#" + $(this).attr("id");
  $(holder + ' button[type="submit"]').attr("disabled", "disabled");

  $.ajax({
    type: "POST",
    url: "/make_booking",
    data: $(this).serialize(),
    dataType: "json",
    success: function(data) {
      $(holder + " .alert").remove();
      $(holder).prepend("<div class='alert alert-success'></div>");
      $(holder + " .alert").append("<p>" + data.responseText + "</p>");
    },
    error: function(data) {
      $(holder + " .alert").remove();
      $(holder).prepend("<div class='alert alert-danger'></div>");
      $(holder + " .alert").append(
        "<p>" + data.responseJSON.responseText + "</p>"
      );
      $(holder + ' button[type="submit"]').removeAttr("disabled");
    }
  });
});

$("#hold_now_modal_form").on("submit", function(e) {
  $.ajaxSetup({
    header: $('meta[name="_token"]').attr("content")
  });
  e.preventDefault(e);
  var holder = "#" + $(this).attr("id");
  $(holder + '> div> button[type="submit"]').attr("disabled", "disabled");
  $("#ajax_hold_act_price").val(
    $("#priceForm")
      .find('input[checked="checked"]')
      .val()
  );
  console.log($("#ajax_hold_act_price").val());
  $.ajax({
    type: "POST",
    url: "/make_hold",
    data: $(this).serialize(),
    dataType: "json",
    success: function(data) {
      console.log(data);
      $(holder + " .alert").remove();
      $(holder).prepend("<div class='alert alert-success'></div>");
      $(holder + " .alert").append("<p>" + data.responseText + "</p>");
    },
    error: function(data) {
      $(holder + " .alert").remove();
      $(holder).prepend("<div class='alert alert-danger'></div>");
      $(holder + " .alert").append(
        "<p>" + data.responseJSON.responseText + "</p>"
      );
      $(holder + ' button[type="submit"]').removeAttr("disabled");
    }
  });
});

$("#register-step1").on("submit", function(e) {
  $.ajaxSetup({
    header: $('meta[name="_token"]').attr("content")
  });

  e.preventDefault(e);
  var holder = "#" + $(this).attr("id");

  if (!$("#deliveryNext").is(":disabled")) {
    $("#register_submited").val("true");
    $(holder + ' input[type="submit"]').attr("disabled", "disabled");
    $.ajax({
      type: "POST",
      url: "/register",
      data: $(this).serialize(),
      dataType: "json",
      success: function(data) {
        $(holder + ' input[type="submit"]').removeAttr("disabled");
        // $(holder + " .alert").remove();
        // $(holder).prepend("<div class='alert alert-success'></div>");
        // $(holder + " .alert").append("<p>Reservation was successful!</p>");
      },
      error: function(data) {
        $(holder + ' input[type="submit"]').removeAttr("disabled");
        // $(holder + " .alert").remove();
        // $(holder).prepend("<div class='alert alert-danger'></div>");
        // $(holder + " .alert").append("<p>Fields marked with * are required!</p>");
        // $(holder + ' button[type="submit"]').removeAttr("disabled");
        // var dataJSON = data.responseJSON;
        // $.each(dataJSON.responseText, function(a, i) {
        // });
      }
    });
  }
});

$("#link_google_calendar").on("submit", function(e) {
  $.ajaxSetup({
    header: $('meta[name="_token"]').attr("content")
  });
  e.preventDefault(e);
  var holder = "#" + $(this).attr("id");
  $(holder + ' button[type="submit"]').attr("disabled", "disabled");
  $.ajax({
    type: "POST",
    url: "/ajax_save_google-id",
    data: $(this).serialize(),
    dataType: "json",
    success: function(data) {
      $(holder + " .alert").remove();
      $(holder).prepend("<div class='alert alert-success'></div>");
      $(holder + " .alert").append(
        "<p>GoogleCalendar ID was successfully saved!</p>"
      );
    },
    error: function(data) {
      $(holder + " .alert").remove();
      $(holder).prepend("<div class='alert alert-danger'></div>");
      $(holder + " .alert").append(
        "<p>We apologize, an error occurred, try later or contact us!</p>"
      );
      $(holder + ' button[type="submit"]').removeAttr("disabled");
    }
  });
});

$("#block_days_calendar").on("submit", function(e) {
  $.ajaxSetup({
    header: $('meta[name="_token"]').attr("content")
  });
  e.preventDefault(e);
  var holder = "#" + $(this).attr("id");
  $(holder + ' button[type="submit"]').attr("disabled", "disabled");

  $.ajax({
    type: "POST",
    url: "/ajax_block_days",
    data: {
      name: $("#name_field").val(),
      start_date: $("#select_start_date").val(),
      end_date: $("#select_end_date").val(),
      act: $("#block_act_id option:selected").val(),
      start_date_single: $("#select_start_date_single").val(),
      type: $("input[name=type]:checked").val()
    },
    dataType: "json",
    success: function(data) {
      // $(holder + " .alert").remove();
      // $(holder).prepend("<div class='alert alert-success'></div>");
      // $(holder + " .alert").append("<p>You successfully blocked days!</p>");
      $("#alerts").removeClass("alert-danger");
      $("#alerts").addClass("alert alert-success");
      $("#alerts").html("<p>You successfully blocked days!</p>");
      $("#alerts").show();
      location.reload();
    },
    error: function(data) {
      $("#alerts").removeClass("alert-success");
      $("#alerts").addClass("alert alert-danger");
      $("#alerts").html("<p>" + data.responseJSON.message + "</p>");
      $("#alerts").show();
      // $(holder + " .alert").remove();
      // $(holder).prepend("<div class='alert alert-danger'></div>");
      // $.each(data.responseJSON, function(a, i) {
      // $("#block_days_calendar" + " .alert").append(
      //   "<p>" + data.responseJSON.message + "</p>"
      // );
      // });
      $(holder + ' button[type="submit"]').removeAttr("disabled");
    }
  });
});

$("#mark_as_seen").click(function(e) {
  $.ajaxSetup({
    headers: {
      "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
    }
  });
  e.preventDefault();

  $.ajax({
    type: "POST",
    url: "/mark_as_seen",
    dataType: "JSON",
    data: { id: $(this).data("id") },
    success: function(data) {
      location.reload();
    }
  });
});

$("#booking_message_flag").on("submit", function(e) {
  $.ajaxSetup({
    header: $('meta[name="_token"]').attr("content")
  });
  e.preventDefault(e);
  var holder = "#" + $(this).attr("id");
  $(holder + ' button[type="submit"]').attr("disabled", "disabled");

  $.ajax({
    type: "POST",
    url: "/ajax_booking_message",
    data: {
      user_id: $("#modal_user_id").val(),
      act_id: $("#modal_act_id").val(),
      booking_id: $("#modal_booking_id").val(),
      message: $("#message-field").val()
    },
    dataType: "json",
    success: function(data) {
      // $(holder + " .alert").remove();
      // $(holder).prepend("<div class='alert alert-success'></div>");
      // $(holder + " .alert").append("<p>Thanks for Flagging your booking to our Admin team. We will respond as soon as possible. Office hours are Monday-Friday 9am-5pm. If you require urgent assistance with your booking please contact your Act directly using their number. You can find this on your bookings section.</p>");
      // location.reload();
      $(".modal-content").html(
        '<div class="alert alert-success flag-success"><p>Thanks for Flagging your booking to our Admin team. We will respond as soon as possible. Office hours are Monday-Friday 9am-5pm. If you require urgent assistance with your booking please contact your Act directly using their number. You can find this on your bookings section.</p></div>'
      );
    },
    error: function(data) {
      $(holder + " .alert").remove();
      $(holder).prepend("<div class='alert alert-danger'></div>");
      $.each(data.responseJSON, function(a, i) {
        $("#booking_message_flag" + " .alert").append("<p>" + i + "</p>");
      });
      $(holder + ' button[type="submit"]').removeAttr("disabled");
    }
  });
});

$("#save_google_calendar_event").on("submit", function(e) {
  $(".sync-google-calendar")
    .find(".sync-google-calendar-form")
    .hide();
  $(
    '<div class="form-group modal-title"><label>Syncing Google Calendar</label></div>'
  ).insertBefore(".calendar-sync-loader");
  $(".sync-google-calendar")
    .find(".calendar-sync-loader")
    .show();
  $.ajaxSetup({
    header: $('meta[name="_token"]').attr("content")
  });

  e.preventDefault(e);
  var holder = "#" + $(this).attr("id");
  $(holder + ' button[type="submit"]').attr("disabled", "disabled");

  $.ajax({
    type: "POST",
    url: "/ajax_save_google_events",
    data: $(this).serialize(),
    dataType: "json",
    success: function(data) {
      $(holder + " .alert").remove();
      $(holder).prepend("<div class='alert alert-success'></div>");
      $(holder + " .alert").append(
        "<p>GoogleCalendar Events was successfully saved!</p>"
      );
      $(".modal-title").html(
        "<label>Google Calendar Sync was successful!</label>"
      );
      $(".sync-google-calendar")
        .find(".calendar-sync-loader")
        .hide();
      $(".sync-google-calendar")
        .find(".success-button")
        .show();
      setTimeout(function() {
        window.location.href = "/bookings";
      }, 2500);
    },
    error: function(data) {
      $(holder + " .alert").remove();
      $(holder).prepend("<div class='alert alert-danger'></div>");
      $(holder + " .alert").append(
        "<p>We apologize, an error occurred, try later or contact us!</p>"
      );
      $(holder + ' button[type="submit"]').removeAttr("disabled");
    }
  });
});
$("#select_acts").on("change", function() {
  var val = $("#select_acts option:selected").val();

  $.ajax({
    url: "/ajaxActsPrices",
    type: "GET",
    dataType: "JSON",
    data: { id: val },
    success: function(data) {
      $("#prices_act").html("");
      var html;
      $.each(data, function(key, value) {
        html = '<div class="col-sm-6"><label for="price_' + value["id"] + '">';
        html += value["name"] + " (Standard: " + value["value"] + ")</label>";
        html +=
          '<input type="number" id="price_' +
          value["id"] +
          '" name="price_option[]" rel="' +
          value["id"] +
          '" value="" class="form-control"></div>';
        $("#prices_act").append(html);
      });
    }
  });
});

$("#save_special_date").on("submit", function(e) {
  $.ajaxSetup({
    header: $('meta[name="_token"]').attr("content")
  });
  e.preventDefault(e);

  var holder = "#" + $(this).attr("id");
  $(holder + ' button[type="submit"]').attr("disabled", "disabled");

  $.ajax({
    type: "POST",
    url: "/ajax_save_special_date",
    data: $(this).serialize(),
    dataType: "json",
    success: function(data) {
      $(holder + " .alert").remove();
      $.each(data.interval, function(i, elem) {
        $('.date-month[rel="' + elem + '"]').addClass("event");
      });
      $("#special_date_modal").modal("hide");
      $(holder + ' button[type="submit"]').removeAttr("disabled");
      location.reload();
    },
    error: function(data) {
      $(holder + " .alert").remove();
      $(holder).prepend("<div class='alert alert-danger'></div>");
      $.each(data.responseJSON, function(a, i) {
        $("#save_special_date" + " .alert").append("<p>" + i + "</p>");
      });
      $(holder + ' button[type="submit"]').removeAttr("disabled");
    }
  });
});

$(function() {
  $("#special_date_modal").on("hidden.bs.modal", function() {
    $(this)
      .find("input,textarea")
      .val("")
      .end();
    $(this)
      .find("select")
      .val("select")
      .end();
    $("#select_start_option").attr("selected", true);
    $("#prices_act").html("");
  });
});

$("#update_special_date").on("submit", function(e) {
  $.ajaxSetup({
    header: $('meta[name="_token"]').attr("content")
  });
  e.preventDefault(e);

  var holder = "#" + $(this).attr("id");
  $(holder + ' button[type="submit"]').attr("disabled", "disabled");

  $.ajax({
    type: "POST",
    url: "/ajax_edit_special_date",
    data: $(this).serialize(),
    dataType: "json",
    success: function(data) {
      $(holder + " .alert").remove();
      $(holder).prepend("<div class='alert alert-success'></div>");
      $(holder + " .alert").append(data.response);
      $(holder + ' button[type="submit"]').removeAttr("disabled");
    },
    error: function(data) {
      $(holder + " .alert").remove();
      $(holder).prepend("<div class='alert alert-danger'></div>");
      $.each(data.responseJSON, function(a, i) {
        $("#update_special_date" + " .alert").append("<p>" + i + "</p>");
      });
      $(holder + ' button[type="submit"]').removeAttr("disabled");
    }
  });
});

function select_user_slectize(all_users, user_old, user_id) {
  var REGEX_EMAIL =
    "([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@" +
    "(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)";
  var select_user = $("#select-to").selectize({
    persist: true,
    maxItems: 1,
    valueField: "id",
    labelField: "name",
    searchField: ["name", "surname", "email"],
    options: all_users,
    render: {
      item: function(item, escape) {
        return (
          "<div>" +
          (item.name
            ? '<span class="name">' +
              escape(item.name) +
              " " +
              escape(item.surname) +
              "</span>"
            : "") +
          (item.email
            ? '<span class="email"> -- ' + escape(item.email) + " --</span>"
            : "") +
          "</div>"
        );
      },
      option: function(item, escape) {
        var label = item.name + " " + item.surname || item.email;
        var caption = item.name ? item.email : null;
        return (
          '<div class="option_user">' +
          "<span>" +
          escape(label) +
          "</span> " +
          (caption
            ? '<span class="caption">' + escape(caption) + "</span>"
            : "") +
          "</div>"
        );
      }
    },
    createFilter: function(input) {
      var match, regex;

      // email@address.com
      regex = new RegExp("^" + REGEX_EMAIL + "$", "i");
      match = input.match(regex);
      if (match) return !this.options.hasOwnProperty(match[0]);

      // name <email@address.com>
      regex = new RegExp("^([^<]*)<" + REGEX_EMAIL + ">$", "i");
      match = input.match(regex);
      if (match) return !this.options.hasOwnProperty(match[2]);

      return false;
    },
    create: function(input) {
      if (new RegExp("^" + REGEX_EMAIL + "$", "i").test(input)) {
        return { email: input };
      }
      var match = input.match(
        new RegExp("^([^<]*)<" + REGEX_EMAIL + ">$", "i")
      );
      if (match) {
        return {
          email: match[2],
          name: $.trim(match[1])
        };
      }
      alert("Invalid email address.");
      return false;
    }
  });
  var selectize_user = select_user[0].selectize;

  if (user_old) {
    selectize_user.setValue(user_old);
  } else {
    selectize_user.setValue(user_id);
  }
}

function closePopupHome() {
  history.pushState(null, null, "/");
}

$("#send_message_btn").on("click", function(event) {
  event.preventDefault();
  if (
    $("#message_text")
      .val()
      .search(/@|\.com|\.co\.uk|\.ie|\.me|www|http|https|[0-9]{6,}/g) == -1
  ) {
    $("#send_message_btn").attr("disabled", true);
    if (
      $(this)
        .find("textarea[name='message']")
        .val() != ""
    ) {
      $("#message-error").removeClass("hidden");
      $("#message-error").addClass("hidden");
      $.ajax({
        url: "/ajaxReplyToQuestion",
        type: "POST",
        dataType: "JSON",
        data: $("#send_message").serialize(),
        success: function(data) {
          $("#message-error").removeClass("hidden");
          location.reload();
        },
        error: function() {
          $("#message-error").html(
            "To preserve buyer protection and prevent fraud, all communication must stay within BookEntertainment. Please remove contact details / URLs from this message and try again."
          );
          $("#message-error").removeClass("hidden");
          $("#send_message_btn").attr("disabled", false);
        }
      });
    } else {
      event.preventDefault();
    }
  } else {
    $("#message-error").html(
      "To preserve buyer protection and prevent fraud, all communication must stay within BookEntertainment. Please remove contact details / URLs from this message and try again."
    );
    $("#message-error").removeClass("hidden");
  }
});
$(".datepicker_button").click(function(e) {
  e.preventDefault();
  $(this)
    .parents()
    .find("div.input-group.date")
    .find("input.search_form_datepicker")
    .datepicker("show");
});

$(".youtube_play_btn, .play_video").on("click", function() {
  // console.log("------------");
  var src =
    "https://www.youtube.com/embed/" +
    $(this).data("video") +
    "?rel=0&autoplay=1";
  $(this)
    .parent()
    .toggleClass("videoWrapper");
  $(this)
    .parent()
    .html(
      '<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' +
        $(this).data("video") +
        '?rel=0&autoplay=1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'
    );
});

$(".uk_map .country").mouseover(function(e) {
  console.log(e.currentTarget.id);
  $("#map-england,#map-scotland,#map-northern-ireland,#map-wales").hide();
  $("#map-" + e.currentTarget.id).show();
});
$(".uk_map .country").mouseout(function() {
  console.log("out");
  $("#map-england,#map-scotland,#map-northern-ireland,#map-wales").hide();
});

$(document).ready(function() {
  if ($(window).width() < 700) {
    var setHeight = $(".category-event-header .image_category").height();
    $(".category-event-header .category_event_description").css({
      height: setHeight + "px"
    });
  }
});
